var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10",attrs:{"elevation":"2"}},[_c('card-title',{attrs:{"icon":"/images/icons/top-icon.png"}},[_vm._v("Saved Quotations")]),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('div',{staticClass:"tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3 md:tw-pb-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"input":_vm.filter},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('hr'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.search ? _vm.filteredData : _vm.quotes,"search":_vm.search,"sort-by":"quoteEffectiveDate","sort-desc":_vm.sortDesc,"page":_vm.pagination.pageNumber,"items-per-page":_vm.pagination.perPage,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingTable,"footer-props":{
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    }},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "pageNumber", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "perPage", $event)}},scopedSlots:_vm._u([{key:"item.quoteCode",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{key:item.quoteCode,attrs:{"text":""},on:{"click":function($event){return _vm.viewMore(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}},{key:"item.savePoint",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{key:item.savePoint,attrs:{"disabled":!item.savePoint,"depressed":"","color":"warning"},on:{"click":function($event){return _vm.resumeQuote(item)}}},[_vm._v(" Resume Quote")])]}},{key:"item.sumAssured",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.sumAssured))+" ")]}},{key:"item.premium",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.premium))+" ")]}},{key:"item.quoteEffectiveDate",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatToHuman")(item.quoteEffectiveDate)))])]}}],null,true)}),_c('saved-quote-dialog',{attrs:{"quoteSummaryData":_vm.quoteSummaryData,"dialogView":_vm.dialogView},on:{"changeDialog":_vm.updateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }